import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CoreRoutingEnum} from "./core/enums/routing-enums.enum";
import { WaitingListComponent } from './shared/components/waiting-list/waiting-list.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: CoreRoutingEnum.AUTH,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: CoreRoutingEnum.INTERNAL_BOARD,
    loadChildren: () => import('./features/internal-layout/internal-layout.module').then((m) => m.InternalLayoutModule),
    canActivate: [AuthGuard],
    data: { userType: 'Employer' }
  },
  {
    path: CoreRoutingEnum.CANDIDATE_LAYOUT,
    loadChildren: () => import('./features/candidate-layout/candidate-layout.module').then((m) => m.CandidateLayoutModule),
    canActivate: [AuthGuard],
    data: { userType: 'Candidate' }
  },
  {
    path: CoreRoutingEnum.ADMIN_LAYOUT,
    loadChildren: () => import('./features/admin-layout/admin-layout.module').then((m) => m.AdminLayoutModule),
    canActivate: [AuthGuard],
    data: { userType: 'Admin' }
  },
  {
    path: CoreRoutingEnum.WAITING_LIST,
    component: WaitingListComponent,
    canActivate: [AuthGuard],
    data: { isWaitingList: true }
  },
  {
    path: "**",
    component: NotFoundPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
