import { Component } from '@angular/core';
import Intercom from '@intercom/messenger-js-sdk';

Intercom({
  app_id: 'nuojv8i8'
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'scrub-valley-system';
}
